<template>
  <div>
    <div v-if="pageLoading">
      <b-row align-h="center">
        <b-spinner variant="primary" />
      </b-row>
    </div>
    <div v-else>
      <b-card-code title="New Customer">
        <!-- Create invoice button -->
        <div :style="`${!isMovil ? 'position: absolute;right: 60px;top: 25px;' : 'display: flex;justify-content: center;margin: 20px;'}`">

          <!-- Upload image -->
          <b-button
            v-if="tabImageActive"
            variant="primary"
            class="p-0 mr-2"
            :disabled="loading"
            @click="resetModalImage"
          >
            <b-overlay
              :show="loading"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
            >
              <b-button
                variant="primary"
                style="position: relative;"
                :disabled="loading"
              >
                <feather-icon
                  icon="LayersIcon"
                  class="mr-25"
                />
                <span>{{ $t("customer.upload_images") }}</span>
              </b-button>
            </b-overlay>
          </b-button>

          <!--Crear nueva factura-->
          <b-button
            v-if="$route.params.id !== 'new'"
            variant="outline-primary"
            @click="createInvoice()"
          >
            <feather-icon
              icon="ImageIcon"
              class="mr-25"
            />
            {{ $t('customer.new_invoice') }}
          </b-button>

          <!-- Create budget button -->
          <b-button
            v-if="$route.params.id !== 'new' && !isMovil"
            variant="primary"
            class="p-0 ml-2 mr-2"
            :disabled="loading"
            @click="setBudgetModal = true"
          >
            <b-overlay
              :show="loading"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
            >
              <b-button
                variant="primary"
                style="position: relative;"
                :disabled="loading"
              >
                <feather-icon
                  icon="FileTextIcon"
                  class="mr-25"
                />
                <span>{{ $t("budget.new_budget") }}</span>
              </b-button>
            </b-overlay>
          </b-button>
          <b-button
            v-if="$route.params.id !== 'new' && isMovil"
            variant="primary"
            class="p-0 ml-2 mr-2"
            :disabled="loading"
            @click="setBudgetModal = true"
          >
            <b-overlay
              :show="loading"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
            >
              <b-button
                variant="primary"
                style="position: relative;"
                :disabled="loading"
              >
                <feather-icon
                  icon="FileTextIcon"
                  class="mr-25"
                />
                <span v-if="!isMovil">{{ $t("budget.new_budget") }}</span>
              </b-button>
            </b-overlay>
          </b-button>

          <!-- Save button -->
          <b-button
            variant="success"
            class="p-0"
            :disabled="loading"
            @click="save"
          >
            <b-overlay
              :show="loading"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="success"
            >
              <b-button
                variant="success"
                style="position: relative;"
                :disabled="loading"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-25"
                />
                <span v-if="!isMovil">{{ $t("common.save") }}</span>
              </b-button>
            </b-overlay>
          </b-button>
        </div>

        <!-- Tabs -->
        <b-tabs
          pills
          class="m-2"
        >
          <!-- Customer tab -->
          <b-tab
            :title="$t('menu.customer')"
            @click="tabImageActive = false"
          >
            <div class="mx-2 mt-1">
              <b-row>
                <b-col
                  lg="4"
                  md="4"
                  sm="12"
                  xs="12"
                >
                  <b-card-body>
                    <h3>{{ $t('customer.details') }}</h3>
                    <hr>
                    <validation-observer ref="refFormObserver">
                      <b-form
                        class="p-2"
                        @reset.prevent
                      >
                        <!-- Name -->
                        <b-form-group
                          :label="$t('products.name')"
                          label-for="form-name"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Name"
                            :rules="{
                              required: true
                            }"
                          >
                            <b-form-input
                              id="form-name"
                              v-model="customer.name"
                              :state="errors.length > 0 ? false : null"
                              trim
                              :placeholder="$t('products.name')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>

                        <!-- Row cp and house number -->
                        <b-row>
                          <b-col
                            cols="12"
                            lg="6"
                            md="6"
                            sm="6"
                          >
                            <!-- Cp -->
                            <b-form-group
                              :label="$t('customer.cp')"
                              label-for="form-city"
                            >
                              <validation-provider
                                name="Cp"
                              >
                                <b-form-input
                                  id="form-cp"
                                  v-model="customer.cp"
                                  trim
                                  :placeholder="$t('customer.cp')"
                                />
                              </validation-provider>
                            </b-form-group>
                          </b-col>

                          <b-col
                            cols="12"
                            lg="6"
                            sm="6"
                            md="6"
                          >
                            <!-- House Number -->
                            <b-form-group
                              :label="$t('customer.house')"
                              label-for="form-city"
                            >
                              <validation-provider
                                name="House"
                              >
                                <b-form-input
                                  id="form-house"
                                  v-model="customer.house_number"
                                  trim
                                  :placeholder="$t('customer.house')"
                                  @input="changeAddress"
                                />
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <!-- Address -->
                        <b-form-group
                          :label="$t('customer.address')"
                          label-for="form-address"
                        >
                          <validation-provider
                            name="Address"
                          >
                            <b-form-input
                              id="form-address"
                              v-model="customer.address"
                              trim
                              :placeholder="$t('customer.address')"
                            />
                          </validation-provider>
                        </b-form-group>

                        <b-row
                          class="justify-content-space-around"
                          align-v="center"
                        >
                          <b-col>
                            <!-- City -->
                            <b-form-group
                              :label="$t('customer.city')"
                              label-for="form-city"
                            >
                              <validation-provider
                                name="City"
                              >
                                <b-form-input
                                  id="form-city"
                                  v-model="customer.city"
                                  trim
                                  :placeholder="$t('customer.city')"
                                />
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <!-- Phone -->
                        <b-form-group
                          :label="$t('user.phone')"
                          label-for="form-name"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Phone"
                            :rules="{
                              required: true
                            }"
                          >
                            <b-form-input
                              id="form-phone"
                              v-model="customer.phone"
                              :state="errors.length > 0 ? false : null"
                              trim
                              :placeholder="$t('user.phone')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>

                        <!-- Email -->
                        <b-form-group
                          :label="$t('user.email')"
                          label-for="form-name"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Email"
                            :rules="{
                              required: true,
                              email: true
                            }"
                          >
                            <b-form-input
                              id="form-email"
                              v-model="customer.email"
                              :state="errors.length > 0 ? false : null"
                              trim
                              :placeholder="$t('user.email')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>

                        <!-- Fiscal number -->
                        <b-form-group
                          :label="$t('customer.fiscal_number')"
                          label-for="form-fiscal_number"
                        >
                          <validation-provider
                            name="Nif"
                          >
                            <b-form-input
                              id="form-fiscal"
                              v-model="customer.fiscal_number"
                              trim
                              :placeholder="$t('customer.fiscal_number')"
                            />
                          </validation-provider>
                        </b-form-group>

                        <!-- Is Business -->
                        <b-form-group
                          label-for="form-is_company"
                        >
                          <validation-provider
                            name="is_company"
                          >
                            <b-form-checkbox
                              id="is_company"
                              v-model="customer.is_company"
                              trim
                            >
                              {{ $t('customer.is_company') }}
                            </b-form-checkbox>
                          </validation-provider>
                        </b-form-group>
                      </b-form>
                    </validation-observer>
                  </b-card-body>
                </b-col>
                <b-col
                  lg="8"
                  md="8"
                  sm="12"
                  xs="12"
                >
                  <b-card-body>
                    <h3>{{ $t('customer.comments') }}</h3>
                    <hr>
                    <b-form-group :label="$t('customer.comment')">
                      <b-form-textarea v-model="newComment" />
                    </b-form-group>
                    <b-button
                      :disabled="!newComment"
                      variant="outline-primary"
                      @click="addComment"
                    >
                      {{ $t('customer.save_comment') }}
                    </b-button>
                    <b-table
                      sticky-header
                      class="mt-2"
                      sort-by="created_at"
                      :sort-desc="true"
                      fixed
                      responsive
                      :fields="commentsFields"
                      :items="comments"
                      sor
                    >
                      <template #cell(actions)="data">
                        <b-button
                          variant="danger"
                          class="btn-icon mrbt"
                          @click="deleteComment(data.item.created_at)"
                        >
                          <feather-icon
                            icon="TrashIcon"
                            size="16"
                          />
                        </b-button>
                      </template>
                    </b-table>
                  </b-card-body>
                </b-col>
              </b-row>
            </div>
          </b-tab>
          <b-tab
            :title="$t('customer.gallery')"
            @click="setImages"
          >
            <div class="mx-2 mt-1">

              <b-card-body class="rows-responsive">
                <b-table
                  responsive="sm"
                  :items="customer.images"
                  :fields="fields"
                >
                  <template #cell(avatar)="data">
                    <b-link
                      @click="zoomImage(data.item.notes, data.item.image)"
                    >
                      <b-img
                        style="max-height: 100px"
                        :src="data.item.image"
                      />
                    </b-link>
                  </template>
                  <template #cell(created_at)="data">
                    <span>{{ formatDate(data.created_at) }}</span>
                  </template>
                  <template #cell(actions)="data">
                    <b-button
                      v-if="data.item.id"
                      variant="success"
                      class="btn-icon mrbt"
                      @click="editDescriptionImgModal(data.item.notes, data.item.id)"
                    >
                      <feather-icon
                        icon="EditIcon"
                        size="16"
                      />
                    </b-button>
                    <b-button
                      variant="danger"
                      class="btn-icon mrbt"
                      @click="deleteModal(data.index, data.item.id)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        size="16"
                      />
                    </b-button>
                  </template>
                </b-table>
              </b-card-body>
            </div>
          </b-tab>
          <b-tab :title="$t('menu.history')"
            @click="tabImageActive = false"
          >
            <b-table
              class="mx-3 my-3"
              responsive="sm"
              :items="history"
              :fields="historyFields"
            >
              <template #cell(type)="data">
                <b-badge :variant="getHistoryBadgeVariant(data.item.budget_id ? 'BUDGET' : 'INVOICE')">
                  {{ data.item.budget_id ? $t('menu.budget') : $t('menu.invoice') }}
                </b-badge>
              </template>
              <template #cell(date)="data">
                <span>{{ data.item.invoices_date || data.item.budget_date }}</span>
              </template>
              <template #cell(total)="data">
                <span>€ {{ data.item.total }}</span>
              </template>
              <template #cell(id)="data">
                <span>#{{ data.item.budget_id || data.item.invoice_id || '-' }}</span>
              </template>
              <template #cell(actions)="data">
                <b-button
                  variant="info"
                  class="btn-icon mrbt"
                  :to="data.item.budget_id ? {
                    name: 'drs-budgets-preview',
                    params: { id: data.item.budget_id }
                  } : { name: 'drs-invoice-preview',
                        params: { id: data.item.invoice_id },
                  }"
                >
                  <feather-icon
                    icon="EyeIcon"
                    size="16"
                  />
                </b-button>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card-code>
      <b-modal
        id="setbudget-modal"
        v-model="setBudgetModal"
        class="modal fade bd-example-modal-xl"
        hide-footer
        hide-header
        centered
        size="md"
      >
        <h4 class="mb-1 mt-1">
          {{ $t('customer.select_mechanic') }}
        </h4>
        <b-row>
          <b-col
            class="mb-2"
          >
            <b-form-select
              v-model="mechanic"
              :options="mechanics"
              :placeholder="$t('budget.modal.options_paid0')"
            />
          </b-col>
        </b-row>
        <b-row
          align-h="end"
          class="px-1"
        >
          <b-button
            variant="outline-secondary"
            class="mr-1"
            @click="setBudgetModal = false"
          >
            {{ $t('common.cancel') }}
          </b-button>
          <b-button
            :disabled="!mechanic"
            variant="primary"
            @click="setBudget"
          >
            {{ $t('common.save') }}
          </b-button>
        </b-row>
      </b-modal>

      <!-- Image Modal -->
      <b-modal
        id="set-image-modal"
        v-model="setImageModal"
        class="modal fade bd-example-modal-xl"
        hide-footer
        hide-header
        centered
        size="md"
      >
        <h4 class="mb-1 mt-1">
          {{ $t('customer.upload_images') }}
        </h4>
        <b-row>
          <b-col
            v-if="showUpload"
            md="12"
          >
            <b-form-group
              :label="$t('new_product.input_fields.image')"
              label-for="v-image"
            >
              <b-form-file
                v-model="image"
                :placeholder="
                  $t('new_product.input_fields.no_file')
                "
                :browse-text="$t('new_product.input_fields.browse')"
                accept="image/*"
              />
            </b-form-group>
          </b-col>
          <!-- specificaties -->
          <b-col md="12">
            <b-form-group
              :label="$t('common.note')"
              label-for="v-notes"
            >
              <b-form-textarea
                v-model="note"
                :placeholder="
                  $t('common.note')
                "
                rows="3"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          align-h="end"
          class="px-1"
        >
          <b-button
            variant="outline-secondary"
            class="mr-1"
            @click="setImageModal = false"
          >
            {{ $t('common.cancel') }}
          </b-button>
          <b-button
            v-if="showUpload"
            variant="primary"
            @click="addImageToCustomer()"
          >
            {{ $t('common.add') }}
          </b-button>
          <b-button
            v-else
            variant="primary"
            @click="saveEditImage(data)"
          >
            {{ $t('common.save') }}
          </b-button>
        </b-row>
      </b-modal>

      <!-- Delete Modal -->
      <b-modal
        id="bv-modal-confirm-delete-event"
        hide-footer
        hide-header
        :visible="setDeleteModal"
      >
        <template #default="{ }">
          <!-- Header -->
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="mb-0">
              {{ $t('customer.delete_image_title') }}
            </h5>

            <div>
              <feather-icon
                class="ml-1 cursor-pointer"
                icon="XIcon"
                size="16"
                @click="setDeleteModal = false"
              />
            </div>
          </div>

          <hr>

          <!-- Body -->
          <div>{{ $t('customer.delete_image_text') }}</div>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="ml-auto"
              @click="setDeleteModal = false"
            >
              {{ $t('common.cancel') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="ml-1"
              @click="deleteImage(imageIndex); setDeleteModal = false"
            >
              {{ $t('common.delete') }}
            </b-button>
          </div>
        </template>
      </b-modal>

      <!-- modal zoom image -->
      <b-modal
        id="modal-zoom"
        hide-footer
        centered
        :title="noteImage"
        ok-only
        ok-title=""
        :visible="setZoomModal"
      >
        <!-- Header -->
        <b-img
          style="width:100%; height: auto"
          :src="viewImage"
        />
      </b-modal>
    </div>
  </div>
</template>

<script>

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCardBody,
  BTabs,
  BTab,
  BLink,
  BSpinner,
  BOverlay,
  BModal,
  BFormSelect,
  BImg,
  BFormTextarea,
  BFormFile,
  BBadge,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import moment from 'moment'
import { isMobile } from 'mobile-device-detect'
import i18n from '@/libs/i18n'
import BCardCode from '@/views/drs/components/BCardCode.vue'
import dbCustomer from '@/models/customer'
import dbBudget from '@/models/budget'
import dbInvoice from '@/models/invoices'
import env from '@/@helpers/envs'
import geoLocation from '@/models/geoLocation'
import generateToken from '@/auth/geoAuth'
// import GalleryTab from './GalleryTab.vue'

export default {
  components: {
    BTable,
    BCardCode,
    BCardBody,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BTabs,
    BTab,
    BLink,
    BSpinner,
    BOverlay,
    // GalleryTab,
    BModal,
    BFormSelect,
    BImg,
    BFormFile,
    BFormTextarea,
    BBadge,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    fields: [
      'id',
      {
        key: 'avatar', label: i18n.t('products.image'),
      },
      {
        key: 'notes', label: i18n.t('orders.modal.notes'),
      },
      {
        key: 'created_at', label: i18n.t('invoice.created_date'),
      },
      {
        key: 'actions', label: i18n.t('invoice.actions'),
      },
    ],
    commentsFields: [
      {
        key: 'created_at', label: i18n.t('customer.created_date'),
      },
      {
        key: 'comment', label: i18n.t('customer.comment'), thStyle: { width: '50%' },
      },
      {
        key: 'created_by', label: i18n.t('customer.created_by'),
      },
      {
        key: 'actions', label: '', thStyle: { width: '10%' },
      },
    ],
    historyFields: [
      {
        key: 'id', label: 'ID',
      },
      {
        key: 'type', label: i18n.t('type'),
      },
      {
        key: 'date', label: i18n.t('invoice.date'),
      },
      {
        key: 'total', label: i18n.t('invoice.total'),
      },
      {
        key: 'status', label: i18n.t('invoice.status'),
      },
      {
        key: 'actions', label: i18n.t('invoice.actions'),
      },
    ],
    required,
    loading: false,
    pageLoading: false,
    setImageModal: false,
    setBudgetModal: false,
    setDeleteModal: false,
    setZoomModal: false,
    showUpload: false,
    tabImageActive: false,
    viewImage: null,
    noteImage: '',
    deleteIndex: null,
    idImage: null,
    isMovil: isMobile,
    deleteId: null,
    image: null,
    note: null,
    customer: {
      name: '',
      house_number: '',
      phone: '',
      email: '',
      address: '',
      city: '',
      cp: '',
      fiscal_number: '',
      latitude: '',
      longitude: '',
      images: [],
      is_company: false,
      comments: [],
    },
    images: [],
    event: [],
    mechanic: null,
    url: env.baseURL,
    history: [],
    newComment: null,
    comments: [],
    userData: JSON.parse(localStorage.getItem('userData')),
  }),
  computed: {
    locale() {
      return this.$i18n.locale
    },
    mechanics() {
      const arr = this.$store.state.app.system?.users_map?.MECHANICS
      const map = new Map()
      if (arr !== undefined) {
        arr.forEach(obj => {
          Object.keys(obj).forEach(key => {
            map.set(key, obj[key])
          })
        })
      }

      const array = []
      if (map !== undefined) {
        map.forEach((val, key) => {
          array.push({ value: Number(key), text: val })
        })
      }
      return array
    },
  },
  async created() {
    this.pageLoading = true

    if (this.$route.params.id !== 'new') {
      const responseBudget = await dbBudget.getAll({
        page: 1,
        perPage: 1000,
        paginate: true,
        word: '',
        status: '',
        dates: '',
        mechanic: '',
        customerId: this.$route.params.id !== 'new' ? parseInt(this.$route.params.id, 10) : 0,
      })

      const responseInvoice = await dbInvoice.getAll({
        page: 1,
        perPage: 1000,
        paginate: true,
        word: '',
        status: '',
        dates: '',
        mechanic: '',
        customerId: parseInt(this.$route.params.id, 10),
      })
      this.history = [...responseBudget.data, ...responseInvoice.data]
      this.customer = await dbCustomer.getById(this.$route.params.id)
      this.comments = this.customer.comments ?? []
    }
    this.pageLoading = false
  },
  methods: {
    async createInvoice() {
      const valid = this.customer

      if (valid) {
        this.$router.push(`/invoices/edit/new?customerId=${this.customer.id}&`)
      }
    },
    deleteComment(createdAt) {
      const commentIndex = this.comments.indexOf(c => c.created_at === createdAt)
      this.comments.splice(commentIndex, 1)
    },
    addComment() {
      this.comments.push({
        comment: this.newComment,
        created_at: this.formatDateWithTime(Date.now()),
        created_by: this.userData.username,
        created_by_userid: this.userData.id,
      })

      this.newComment = null
    },
    getHistoryBadgeVariant(item) {
      if (item === 'BUDGET') {
        return 'success'
      }

      return 'primary'
    },
    resetModalImage() {
      this.id = ''
      this.image = null
      this.name = ''
      this.note = ''
      this.showUpload = true
      this.setImageModal = true
    },
    pushToImages(base64String, fileName) {
      const obj = {
        name: fileName,
        image: base64String,
        is_default: false,
        notes: this.note,
      }

      this.customer.images.push(obj)
      this.image = ''
      this.note = ''
      this.showUpload = false
      this.setImageModal = false
    },
    editDescriptionImgModal(note, id) {
      this.showUpload = false
      this.setImageModal = true
      this.note = note
      this.idImage = id
    },
    async saveEditImage() {
      const image = {
        idImage: this.idImage,
        notes: this.note,
      }

      const response = await dbCustomer.editImage(image)
      this.customer = response
      this.setImageModal = false

      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: `${this.$i18n.t('toast.title.done')}`,
          icon: 'CheckIcon',
          variant: 'success',
          text: `${this.$i18n.t('toast.customer.image_edited')}`,
        },
      })
    },
    getBase64(file, pushToImages) {
      const reader = new FileReader()
      // eslint-disable-next-line func-names
      reader.onloadend = function () {
        pushToImages(reader.result, file.name)
      }
      reader.readAsDataURL(file)
    },
    zoomImage(note, image) {
      this.viewImage = image
      this.noteImage = note
      this.setZoomModal = !this.setZoomModal
    },
    async addImageToCustomer() {
      this.images = []
      this.getBase64(this.image, this.pushToImages)
      // setTimeout(this.save(), 2000)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    changeAddress() {
      const payload = { cp: this.customer.cp, house_number: this.customer.house_number }
      payload.cp = payload.cp.replace(/[^a-zA-Z0-9]/g, '')
      payload.house_number = payload.house_number.replace(/[^0-9]/g, '')
      if (payload.cp.length === 9 || payload.cp.length === 6) {
        if (payload.house_number.length > 0) {
          this.getLocation(payload)
        }
      }
    },
    async getLocation(info) {
      const bearer = await generateToken()
      const payload = {
        cp: info.cp,
        house_number: info.house_number,
        bearer,
      }
      this.fillAddress = await geoLocation.get(payload)
      this.customer.address = this.fillAddress.street
      this.customer.city = this.fillAddress.city
      this.customer.latitude = this.fillAddress.latitude
      this.customer.longitude = this.fillAddress.longitude
    },
    async save() {
      this.$refs.refFormObserver.validate().then(async success => {
        if (success) {
          try {
            this.loading = true
            const finalObj = JSON.parse(JSON.stringify(this.customer))
            if (this.$route.params.id === 'new') finalObj.customerId = null
            else finalObj.customerId = this.customer.id

            finalObj.comments = this.comments

            await dbCustomer.save(finalObj)
            this.$router.push({ path: '/customers/customers', query: { page: this.$route.query.page } })
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `${this.$i18n.t('toast.title.new_customer')}`,
                icon: 'CheckIcon',
                variant: 'success',
                text: `${this.$i18n.t('toast.text.success')}`,
              },
            })

            this.loading = false
          } catch (e) {
            /* eslint-disable-next-line no-console */
            console.error(e)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `${this.$i18n.t('toast.title.new_customer')}`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: `${this.$i18n.t('toast.text.error')}`,
              },
            })
            this.loading = false
          }
        }
      })
    },
    setImagesArray(images) {
      this.images = images
    },
    async setImages() {
      this.tabImageActive = true
      const filesAux = []
      if (this.customer.images) {
        this.customer.images.forEach(img => {
          filesAux.push(this.b64toFile(img))
        })
      }
      // Da error en consola no se su funcionalidad.
      // this.$refs.gallery.setFiles(filesAux)
    },
    b64toFile(obj) {
      const byteString = atob(obj.image.split(',')[1])
      const ab = new ArrayBuffer(byteString.length)
      const ia = new Uint8Array(ab)

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }
      const blob = new Blob([ab], { type: 'image/jpeg' })
      return new File([blob], obj.name)
    },
    deleteModal(index, id) {
      this.setDeleteModal = !this.setDeleteModal
      this.deleteIndex = index
      this.deleteId = id
    },
    async deleteImage() {
      this.customer.images.splice(this.deleteIndex, 1)
      // this.$emit('deleteImage', index)
      // this.$refs.updloadImages.deleteImg(this.deleteIndex)
      await dbCustomer.deleteImage(this.deleteId)
    },
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY')
    },
    formatDateWithTime(value) {
      return moment(value).format('YYYY-MM-DD HH:mm:ss')
    },
    /*
    clearAll() {
      for (let i = 0; i < this.images.length; i += 1) {
        const { id } = this.customer.images[i]
        dbCustomer.deleteImage(id)
      }
      this.customer.images = []
      this.images = []
    },
    */
    backToListCustomer() {
      this.$router.push({ name: 'drs-customers' })
    },
    currentDate() {
      const current = new Date()
      const date = `${current.getDate()}-${current.getMonth() + 1}-${current.getFullYear()}`
      return date
    },
    // eslint-disable-next-line consistent-return
    getKeyByValue(nameKey, myArray) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < myArray.length; i++) {
        if (myArray[i].value === nameKey) {
          return myArray[i]
        }
      }
    },
    async setBudget() {
      const valid = this.customer

      if (valid) {
        this.event.customer = this.customer
        this.event.customer.customerId = this.customer.id
        delete this.customer.id
        const mechanicName = this.getKeyByValue(this.mechanic, this.mechanics)

        const payload = {
          customer: this.event.customer || {},
          mechanicUsername: mechanicName.text,
          concept: '',
          date: this.event.customer.date = moment(this.currentDate(), 'DD-MM-YYYY').format('YYYY-MM-DD') || '',
          status: 'PENDING',
          products: [],
          description: '',
          budgetId: '',
          remark: '',
        }

        try {
          const { data } = await dbBudget.set(payload)
          this.$router.push(`/budgets/edit/${data.id}`)
        } catch (error) {
          /* eslint-disable-next-line no-console */
          console.error(error)
        }
      }
    },
  },
}
</script>
<style>
#modal-zoom .modal-dialog {
  max-width: 900px;
}
</style>
